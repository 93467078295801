<template>
  <button class="btn btn-primary download" @click="downloadPDF">
    Download
  </button>
</template>

<script>
import download from 'downloadjs';
import * as Sentry from '@sentry/vue';

export default {
  name: 'DownloadPDFButtonComponent',
  props: {
    href: {
      type: String,
      default: '/print/anamnesis',
    },
  },
  methods: {
    downloadPDF() {
      this.$store.state.loading = true;
      this.$http.post(this.href, this.$store.getters.ANAMNESIS, {
        responseType: 'blob',
      })
        .then((response) => {
          const content = response.headers['content-type'];
          download(response.data, 'jouw overzicht.pdf', content);
        })
        .catch((err) => {
          Sentry.captureException(err);
        })
        .finally(() => {
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style scoped>

</style>
