<template>
  <Reviews v-if="reviews" :list="reviews" />
</template>

<script>
import getPlaceDetails from '@/helpers/googlePlaceDetails';

/* eslint-disable import/extensions */
import Reviews from '@/components/widgets/Reviews';

export default {
  name: 'HomeReviews',

  components: {
    Reviews,
  },

  props: {
    gMapsKey: String,
  },

  data: () => ({
    reviews: null,
  }),

  async created() {
    const { reviews } = await getPlaceDetails(this.gMapsKey, ['reviews']);

    if (reviews) {
      this.reviews = reviews;
    }
  },
};
</script>
