/* eslint-disable */
import axios from 'axios';

export class TaggingEvent {
  constructor() {
    this.events = {};
    this.currency = 'EUR';
  }

  addData(eventObject, prop, value) {
    if (!this.events[eventObject.event]) {
      throw new Error(`Event '${eventObject}' does not exist. Add the event first.`);
    }

    eventObject.ecommerce[prop] = value;
  }

  addEvent(eventName, value = 0) {
    const { taggingHelpers } = window;

    this.events[eventName] = {
      event: eventName,
      currency: this.currency,
      ecommerce: {
        value: value,
        items: [],
      },
      device: taggingHelpers.getDeviceInfo(),
      marketing: taggingHelpers.getMarketingObject(),
      event_time: new Date().toISOString(),
      page_title: document.title,
      page_hostname: window.location.hostname,
      page_location: window.location.href,
      page_path: window.location.pathname,
      page_referrer: document.referrer,
      user_agent: window.navigator.userAgent,
    };

    return this.events[eventName];
  }

  addItem(eventObject, item) {
    if (!this.events[eventObject.event]) {
      throw new Error(`Event '${eventObject}' does not exist. Add the event first.`);
    }

    item.affiliation = 'ZIZ';
    item.item_brand = 'ZIZ';

    const itemTotal = ((item.price ?? 0) * (item.quantity ?? 0)).toFixed(2);
    eventObject['ecommerce']['value'] =
        (parseFloat(eventObject['ecommerce']['value']) + parseFloat(itemTotal)).toFixed(2);

    eventObject.ecommerce.items.push(item);
  }

  async sendToAnalytics() {
    for (const eventName in this.events) {
      if (!this.events.hasOwnProperty(eventName)) {
        continue;
      }

      const trackEvent = this.events[eventName];
      // console.log(`🔥 Fired '${eventName}'`);
      // console.log(trackEvent);
      const url = `https://tm.yournextagency.com/${eventName}`;

      try {
        const response = await axios.post(url, trackEvent);
        // console.log(`Status: ${response.status}`);
        // console.log('Body: ', response.data);
      } catch (error) {
        console.error(`Error: ${error.message}`);
      }
    }
  }
}
