<template>
  <div
    role="dialog"
    :class="$style.root"
  >
    <div
        :class="$style.bg"
        aria-hidden="true"
        @click="handleClose"
    />
    <div :class="isVideo ? $style.video : $style.modal">
      <button
        type="button"
        aria-label=""
        :class="$style.closeButton"
        @click="handleClose"
      >
        <Icon name="close" :class="$style.icon" />
      </button>
      <slot />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import Icon from '@/components/Icons';

export default {
  name: 'ModalComponent',

  components: {
    Icon,
  },

  props: {
    isVideo: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    document.body.insertBefore(this.$el, document.body.lastChild);

    document.documentElement.style.marginRight = `${window.innerWidth - document.documentElement.offsetWidth}px`;
    document.documentElement.style.overflow = 'hidden';
    document.addEventListener('keydown', this.handleDocumentKeyDown);
  },

  beforeDestroy() {
    document.documentElement.style.overflow = null;
    document.documentElement.style.marginRight = null;
    document.removeEventListener('keydown', this.handleDocumentKeyDown);
  },

  methods: {
    handleClose() {
      this.$emit('close');
    },

    handleDocumentKeyDown(e) {
      if (e.key === 'Escape') {
        this.handleClose();
      }
    },
  },
};
</script>

<style lang="scss" module scoped>
  @import './index';
</style>
