<template>
  <div class="form-group">
    <div v-if="title"
           class="form-group__title">
      {{ computedTitle[0] }}
      <a v-if="link"
         class="results-products__item-add_to_cart"
         @click.prevent="openPopup"
         :target="link.target"
         href="#">{{ link.text }}</a>
      {{ computedTitle[1] }}
    </div>
    <slot/>
    <PopupComponent v-if="isPopupShown"
                    @closePopup="closePopup">
      <!--<iframe :src="link.href"/>-->
      <h1 class="h2 faq__page-title">{{ heading }}</h1>
      <div v-html='cont'></div>
    </PopupComponent>
  </div>
</template>

<script>
import PopupComponent from './PopupComponent.vue';

export default {
  name: 'FieldGroupComponent',
  components: {
    PopupComponent,
  },
  data: () => ({
    isPopupShown: false,
  }),
  props: {
    title: {
      type: String,
      required: false,
    },
    link: {
      type: Object,
      default: null,
    },
    heading: {
      type: String,
      default: '',
    },
    cont: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedTitle() {
      return this.title.split('{link}');
    },
  },
  methods: {
    openPopup() {
      this.isPopupShown = true;
      const url = window.location.origin;
      if (this.isPopupShown) {
        window.history.pushState(url, 'zorgkosten', 'informedconsent');
      }
      return false;
    },
    closePopup() {
      this.isPopupShown = false;
      window.history.back();
      return false;
    },
  },

};
</script>

<style scoped>

</style>
