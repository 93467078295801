<template>
  <div>
    <div v-for="(typeProducts, type) in products" :key="type">
      <div v-if="typeProducts.length > 0"
           class="results-products__group">
        <transition name="fade" v-if="type === 'common'">
          <div
              class="results-products__hide"
              :class="{active: show}"
          >
            <h3 class="results-products__group-title px-md-0">{{ lang[type] }}</h3>
            <div class="results-products__list row">
              <ResultProductComponent
                  v-for="product in typeProducts"
                  :key="product.id"
                  v-bind="{ product, type }"/>
            </div>
          </div>
        </transition>
        <div v-else>
          <h3 class="results-products__group-title px-md-0">{{ lang[type] }}</h3>
          <div class="results-products__list row">
            <ResultProductComponent
                v-for="product in typeProducts"
                :key="product.id"
                v-bind="{ product, type }"/>
          </div>
        </div>

        <div
            class="d-block d-md-none"
            v-if="type === 'common'">
          <button
              class="btn btn-grey btn-block btn-arrow"
              :class="{active: show}"
              @click="show = !show"
          >{{ showList }}
            <i><svg xmlns="http://www.w3.org/2000/svg" width="10" height="18"><path d="M1 1l8 8-8 8" stroke="#060f21" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"/></svg></i>
          </button>
        </div>
        <CartComponent
            v-if="type === 'common'"
            :cart-info="true"
            :show-delete-button="true"
            :cart-action="true"
            :agreement-link="agreementLink"
            :informedconsent-heading="informedconsentHeading"
            :informedconsent-cont="informedconsentCont"
            action-text="Ga verder"
            action="/checkout"
            class="d-block d-md-none pb-5"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import dangerProducts from '../helpers/dangerProducts';
import ResultProductComponent from './ResultProductComponent.vue';
import CartComponent from './CartComponent.vue';
import getGtmProduct from '../helpers/gtmProduct';
import { TaggingEvent } from '@/helpers/tagging';

export default {
  name: 'ResultProductsComponent',
  components: {
    ResultProductComponent,
    CartComponent,
  },
  data() {
    return {
      products: [],
      header: '',
      zizId: '',
      lang: {
        recommended: 'Overweeg bij twijfel',
        required: 'Jouw persoonlijke advies',
        common: 'Niet aanbevolen voor jou',
      },
      show: false,
    };
  },
  props: {
    getProductsLink: {
      type: String,
      default: '/calculate/products',
    },
    agreementLink: {
      type: Object,
      default: null,
    },
    informedconsentCont: {
      type: String,
      default: '',
    },
    informedconsentHeading: {
      type: String,
      default: '',
    },
    consultAction: {
      type: String,
      default: '/consult',
    },
  },
  computed: {
    ...mapGetters(['ANAMNESIS', 'IS_ANAMNESIS_VALID']),

    showList() {
      if (this.show) {
        return 'Verberg niet aanbevolen testen';
      }

      return 'Bekijk niet aanbevolen testen';
    },
  },
  mounted() {
    if (!this.IS_ANAMNESIS_VALID) {
      window.location.href = this.consultAction;

      return;
    }

    const loadingBlock = this.$parent.$refs.loading;

    this.$http.post(this.getProductsLink, this.ANAMNESIS)
      .then((data) => {
        this.zizId = data.data.zizId;
        this.header = data.data.header;
        this.products = data.data;
        delete this.products.header;
        delete this.products.zizId;
        this.pageUpdate();
        this.changePageHeading();

        // Сохраняем все продукты в store
        const allProducts = [].concat(...Object.values(data.data)
          .filter((a) => a instanceof Array));
        this.$store.commit('SET_ALL_PRODUCTS', allProducts);

        const items = allProducts.map((product, i) => getGtmProduct(product, i));

        const taggingEvent = new TaggingEvent();

        const viewItemListEvent = taggingEvent.addEvent('view_item_list');
        items.forEach((item) => {
          taggingEvent.addItem(viewItemListEvent, item);
        });

        const viewPromotionEvent = taggingEvent.addEvent('view_promotion');
        items.forEach((item) => {
          taggingEvent.addItem(viewPromotionEvent, item);
        });

        taggingEvent.sendToAnalytics();
      })
      .catch((err) => {
        Sentry.captureException(err);
        window.location.href = this.consultAction;
      })
      .finally(() => {
        loadingBlock.classList.add('d-none');
        this.$store.state.loading = false;
      });
  },
  methods: {
    pageUpdate() {
      this.$parent.$refs.page__heading.innerHTML = this.header;
      if (this.products.risk > 0) {
        this.$parent.$refs.mainSection.classList.add('danger');
        this.$parent.$refs.content.classList.add('danger');
      }
    },
    changePageHeading() {
      /* eslint-disable-next-line */
      for (const products of Object.values(this.products)) {
        /* eslint-disable-next-line */
        for (const product of Object.values(products)) {
          const l = product.letter;
          if (dangerProducts.some((letter) => letter === l) && product.risk > 0) {
            break;
          }
        }
      }
    },

  },
};
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
