<template>
  <transition
    v-if="zizRating && gMapsKey"
    name="fade"
  >
    <div class="page-home__banner-rating-wrap">
      <GoogleRating
        :rating="zizRating"
        is-show-digits
      />
    </div>
  </transition>
</template>

<script>
import getPlaceDetails from '@/helpers/googlePlaceDetails';
// eslint-disable-next-line import/extensions
import GoogleRating from '@/components/ui/GoogleRating';

export default {
  name: 'HomeRating',

  components: {
    GoogleRating,
  },

  props: {
    gMapsKey: String,
  },

  data: () => ({
    zizRating: null,
  }),

  async created() {
    const { rating } = await getPlaceDetails(this.gMapsKey, ['rating']);

    this.zizRating = rating;
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active { transition: opacity $transition-time; }
.fade-enter { opacity: 0; }
</style>
